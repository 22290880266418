import { APP_PREFIX_PATH, AUTHENTICATED_ENTRY } from "configs/AppConfig";
import { Navigate, Outlet } from "react-router-dom";
import AppRoute from "./AppRoute";
import { DevicesComponent, HomeComponent, SettingsComponent } from "./components";

const ProtectedRoleRoutes = ({ user, path }) => {
  const role = user?.role?.title;

  if (role === "admin") {
    switch (path) {
      case "/":
        return <Navigate replace to={AUTHENTICATED_ENTRY} />;
      case `${APP_PREFIX_PATH}/dashboards/home`:
        return (
          <AppRoute routeKey="dashboard.search" component={HomeComponent} />
        );
      case `${APP_PREFIX_PATH}/dashboards/settings`:
        return <AppRoute routeKey="home.Home" component={SettingsComponent} />;
      case `${APP_PREFIX_PATH}/dashboards/devices`:
        return <AppRoute routeKey="home.devices" component={DevicesComponent} />;
      default:
        return <Navigate replace to={AUTHENTICATED_ENTRY} />;
    }
  } else if (role === "mine-owner") {
    switch (path) {
      case "/":
        return <Navigate replace to={AUTHENTICATED_ENTRY} />;
      case `${APP_PREFIX_PATH}/dashboards/home`:
        return (
          <AppRoute routeKey="dashboard.search" component={HomeComponent} />
        );
      default:
        return <Navigate replace to={AUTHENTICATED_ENTRY} />;
    }
  }

  return <Outlet />;
};

export default ProtectedRoleRoutes;
